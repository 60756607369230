import type { ReactNode } from 'react';

import type { EmissionDetailTypeEnum, EmissionDetailSubtypeEnum } from '../../services/graphql/generated';

export enum EmissionDetailTypeGroupEnum {
  FUEL_BASED = 'FUEL_BASED',
  ELECTRICITY_BASED = 'ELECTRICITY_BASED',
  OTHER = 'OTHER',
}

export interface LifecycleTypeBreakdown {
  type: EmissionDetailTypeEnum | EmissionDetailTypeGroupEnum | EmissionDetailSubtypeEnum;
  value: number;
  key?: string;
  unit: string;
  label?: ReactNode;
  breakdown?: LifecycleTypeBreakdown[];
}

export enum EmissionBreakdownTypeEnum {
  TCE = 'TCE',
  ADMINISTRATIVE_ZONE = 'ADMINISTRATIVE_ZONE',
  LIFECYCLE = 'LIFECYCLE',
  TANK_TO_WHEEL_GHG = 'TANK_TO_WHEEL_GHG',
  OTHER_POLLUTANTS = 'OTHER_POLLUTANTS',
  ROAD_CONDITIONS = 'ROAD_CONDITIONS',
  MODE = 'MODE',
  ELECTRICITY = 'ELECTRICITY',
}

export interface EmissionBreakdownType {
  type: EmissionBreakdownTypeEnum;
  value: number;
  key?: string;
  unit: string;
  label?: ReactNode;
  maximumFractionDigits?: number;
}
